var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-page" },
    [
      _vm.error.statusCode === 404
        ? _c("h1", [
            _vm._v("\n    " + _vm._s(_vm.$t("Page not found")) + "\n  "),
          ])
        : _c("h1", [
            _vm._v("\n    " + _vm._s(_vm.$t("An error occurred")) + "\n  "),
          ]),
      _vm._v(" "),
      _c("SfButton", { attrs: { link: "/" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("Back to Home page")) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }