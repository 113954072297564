import { Context, NuxtAppOptions } from '@nuxt/types';
import { LocaleObject } from 'nuxt-i18n';
import { StoreTypeEnum } from "./StoreTypeEnum";
const findLocaleBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>) => locales.find((locale) => ((typeof locale === 'object' ? locale.code : locale) === storeCode));

const findCurrencyBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>): string => {
  const match = locales.find((locale) => typeof locale === 'object' && locale.code === storeCode) as LocaleObject | undefined;
  return match?.defaultCurrency;
};

/**
 * Prepare new cookie string based on app state.
 *
 * @param app {NuxtAppOptions}
 * @param newStoreCode {string}
 * @param currency {string}
 * @returns {string}
 */
const prepareNewCookieString = (app: NuxtAppOptions, newStoreCode: string, currency: string) => {
  const apiState = app.$vsf.$magento.config.state;
  const customerTokenCookie = apiState.getCustomerToken();
  const cartIdCookie = apiState.getCartId();
  let cookie = `vsf-store=${newStoreCode}; `;
  cookie += `vsf-locale=${newStoreCode}; `;
  cookie += `vsf-currency=${currency}; `;
  cookie += `vsf-country=${apiState.getCountry()}; `;

  if (customerTokenCookie) {
    cookie += `vsf-customer=${customerTokenCookie}; `;
  }

  if (cartIdCookie) {
    cookie += `vsf-cart=${cartIdCookie} `;
  }
  return cookie;
};

export default ({ app, route }: Context) => app.$vsf.$magento.client.interceptors.request.use(async (request) => {
  const {
    $vsf: { $magento: { config: { state } } },
    i18n,
  } = app;
  const currentStoreCode = app.$vsf.$magento.config.state.getStore(); // localhost:3000/default
  const sysStoreCode = process.env.VSF_STORE_NAME;
  let checkStore = false
  const stores = [...Object.values(StoreTypeEnum), ""]
  const i18nCurrentLocaleCode = state.getLocale();
  checkStore = stores.includes(sysStoreCode)  && sysStoreCode !== i18nCurrentLocaleCode
    if (checkStore) {
      await i18n.setLocale(sysStoreCode);
      let currency = findCurrencyBasedOnMagentoStoreCode(sysStoreCode, i18n.locales);
      if (!currency) currency = 'AUD'
      state.setStore(sysStoreCode);
      state.setLocale(sysStoreCode);
      state.setCurrency(currency);
      // eslint-disable-next-line no-param-reassign
      request.headers.cookie = prepareNewCookieString(app, sysStoreCode, currency);
    }
    return request;
});
