var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-navigation__subcategories" }, [
    _c(
      "div",
      { staticClass: "header-navigation__subcategories-inner" },
      [_c("HTMLContent", { attrs: { content: _vm.content } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }