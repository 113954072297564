var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-navigation",
      on: {
        mouseleave: function ($event) {
          return _vm.setCurrentCategory(null)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "sf-header-navigation-item__item sf-header-navigation-item__item--desktop",
        },
        _vm._l(_vm.categoryTree, function (category, index) {
          return _c("HeaderNavigationItem", {
            key: index,
            ref: "lvl0CatRefs",
            refInFor: true,
            staticClass: "nav-item",
            attrs: {
              "data-testid": category.uid,
              label: category.name,
              link: _vm.getCatLink(category),
              tabindex: "1",
              "aria-haspopup": "true",
              "data-index": index,
              "has-children": _vm.hasChildren(category),
            },
            nativeOn: {
              mouseenter: function ($event) {
                $event.preventDefault()
                return _vm.onMouseEnter(category)
              },
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.setCurrentCategory(category)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.setCurrentCategory(null)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "right", 39, $event.key, [
                      "Right",
                      "ArrowRight",
                    ])
                  ) {
                    return null
                  }
                  if ("button" in $event && $event.button !== 2) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.navRight()
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  ) {
                    return null
                  }
                  if ("button" in $event && $event.button !== 0) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.navLeft()
                },
              ],
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.setFocus($event)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.hasChildren(_vm.currentCategory)
        ? _c("HeaderNavigationSubcategories", {
            attrs: {
              "current-category": _vm.currentCategory,
              "has-focus": _vm.hasFocus,
            },
            on: { hideSubcategories: _vm.focusRootElementAndHideSubcategories },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }