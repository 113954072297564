var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nuxt-link",
    { staticClass: "sf-header__logo", attrs: { to: "/" } },
    [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: {
            src: "/homepage/myjs-logo.png",
            alt: "My Jewellery Story",
            width: "170",
            height: "70",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }