































export interface CategoryBlockMenu {
  name: String
  path: String
  blockKey: String
}

import ParentMenuBlocks from '~/assets/parentMenuBlocks.json'
import { defineComponent, ref, useFetch, } from "@nuxtjs/composition-api";
import { useContent, useUiHelpers } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import HeaderMegaBlockMenu from './HeaderMegaBlockMenu.vue';
import { parse } from 'node-html-parser';

export default defineComponent({
  name: 'HeaderNavigationBlocks',
  components: {
    HeaderNavigationItem,
    HeaderMegaBlockMenu
  },
  setup() {
    const {
      loadBlocks,
    } = useContent();
    const menuBlocks = ref<CmsBlock[]>([]);
    const currentCategory = ref<CategoryBlockMenu | null>(null);
    useFetch(async () => {
      const blocks = await loadBlocks({ identifiers:  ParentMenuBlocks.map(m => m.blockKey)});
      const parseCategory = [];
      blocks.forEach(b => {
        const dom = parse(b.content);
        const categoryItems = {
          blockKey: b.identifier,
          children: []
        };
        dom.getElementsByTagName('ul').forEach(lv1 => {
          lv1.getElementsByTagName('li').forEach(li => {
            if (li.getElementsByTagName('h4').length) {
              const child = {
                title: li.getElementsByTagName('h4')[0]?.textContent,
                items: []
              };
              li.getElementsByTagName('li').forEach(lv2 => {
                const item = {
                  path: lv2.getElementsByTagName('a')[0].getAttribute('href'),
                  text: lv2.textContent
                }
                child.items.push(item)
              });
              categoryItems.children.push(child)
            }
          })
        })
        parseCategory.push(categoryItems)
      })
      menuBlocks.value = blocks;
    });

    const categoryTree = ref<CategoryBlockMenu[]>(ParentMenuBlocks);
    const { getCatLink } = useUiHelpers();
    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    let lvl0CatFocusIdx = 0;
    let focusedElement : HTMLElement | null = null;
    const setCurrentCategory = (category: CategoryBlockMenu | null) => {
      currentCategory.value = category;
    };

    const hasChildren = (category: CategoryBlockMenu) => menuBlocks.value.some(m => m.identifier === category?.blockKey);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value && lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value && lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const onMouseEnter = (category: CategoryBlockMenu) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    };
    return {
      getCatLink,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0CatRefs,
      navRight,
      navLeft,
      hasFocus,
      onMouseEnter,
      categoryTree,
      menuBlocks,
    }
  }
});
