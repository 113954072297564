












import { SfLink, SfIcon } from "@storefront-ui/vue";
import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "HeaderNavigationItem",
  components: {
    SfLink,
    SfIcon,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: "",
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
});
