import { defineStore } from 'pinia';
import { CategoryResult } from '~/composables';
import categoryListGql from './graphql/categoryList.gql';
import menuNotSale from "~/assets/menuNotSale.json";
import menuSale from "~/assets/menuSale.json";
import { createCustomMenu } from "~/modules/catalog/category/composables/useCategory/index"
import { CategoryTree } from '@vue-storefront/magento-api';
interface CategoryState {
  rawCategories: CategoryResult | null
  rawCategoryList: CategoryTree[] | null
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
    rawCategoryList: null,
  }),
  actions: {
    async load() {
      const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({ query: categoryListGql });
      this.rawCategories = data?.categories ?? null;
    },
    async setCategoryList(categories: CategoryTree[]): Promise<void> {
      this.rawCategoryList = categories
    }
  },
  getters: {
    categories(state) {

      const categoryNotSale = createCustomMenu()(menuNotSale)
      const categorySale = createCustomMenu()(menuSale)
      if (state.rawCategories === null) {
        return null;
      }
      const menuCategory = state.rawCategories?.items[0]
      let categorySaleMenu = categorySale
      if(!categorySale[0].children[0].name){
        const children = categorySale[0].children[0].children
        delete categorySale[0]['children']
        categorySaleMenu = categorySale[0]
        categorySaleMenu['children'] = children
      }
      
      const cat = categoryNotSale.concat(categorySaleMenu)
      menuCategory['children'] = cat
      return menuCategory;
    },
    categoryList(): CategoryTree[] {
      return this.rawCategoryList
    },
  },
});
