import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _300f0c71 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _1c8662dd = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "" */))
const _2d995724 = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "" */))
const _03c11c08 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _50277415 = () => interopDefault(import('../pages/PackagingGiftWrapping.vue' /* webpackChunkName: "" */))
const _59bf2442 = () => interopDefault(import('../pages/PaymentOptions.vue' /* webpackChunkName: "" */))
const _46c9eb80 = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _0fa47167 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _25d16cfe = () => interopDefault(import('../pages/ReturnsRefund.vue' /* webpackChunkName: "" */))
const _0cdef0cc = () => interopDefault(import('../pages/ShippingDelivery.vue' /* webpackChunkName: "" */))
const _b33c219c = () => interopDefault(import('../pages/TermsAndConditions.vue' /* webpackChunkName: "" */))
const _a9485e84 = () => interopDefault(import('../pages/Testimonials.vue' /* webpackChunkName: "" */))
const _2179b693 = () => interopDefault(import('../pages/Account.vue' /* webpackChunkName: "pages/Account" */))
const _1d513f9d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _41ec6cd8 = () => interopDefault(import('../modules/customer/pages/MyAccount/Credit/MyCredit.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _32722494 = () => interopDefault(import('../modules/customer/pages/MyAccount/Credit/MyCreditRedeem.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _c5f8b548 = () => interopDefault(import('../modules/checkout/components/Success.vue' /* webpackChunkName: "" */))
const _7cfe6dbe = () => interopDefault(import('../modules/checkout/components/Error.vue' /* webpackChunkName: "" */))
const _d3367a88 = () => interopDefault(import('../pages/paypal/Cancel.vue' /* webpackChunkName: "pages/paypal/Cancel" */))
const _75868672 = () => interopDefault(import('../pages/paypal/Return.vue' /* webpackChunkName: "pages/paypal/Return" */))
const _24ce9645 = () => interopDefault(import('../modules/checkout/components/VsfPaymentProvider.vue' /* webpackChunkName: "" */))
const _ae68fb22 = () => interopDefault(import('../modules/customer/pages/MyAccount/Create.vue' /* webpackChunkName: "" */))
const _61ef6e2e = () => interopDefault(import('../modules/customer/pages/MyAccount/Print/PrintSingleOrder.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-myjs-my-jewellery-story-our-story.html",
    component: _300f0c71,
    name: "about-us___myjsusview___"
  }, {
    path: "/contact-us.html",
    component: _1c8662dd,
    name: "contact-us___myjsusview___"
  }, {
    path: "/error.html",
    component: _2d995724,
    name: "error_find___"
  }, {
    path: "/faq.html",
    component: _03c11c08,
    name: "faq___myjsusview___"
  }, {
    path: "/myjsauview",
    component: _763b5a69,
    name: "home___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewabout-myjs-my-jewellery-story-our-story.html",
    component: _300f0c71,
    name: "about-us___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewcontact-us.html",
    component: _1c8662dd,
    name: "contact-us___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewerror.html",
    component: _2d995724,
    name: "error_find___myjsauview"
  }, {
    path: "/myjsauviewfaq.html",
    component: _03c11c08,
    name: "faq___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewpackaging-gift-wrapping.html",
    component: _50277415,
    name: "packaging-gift-wrapping___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewpayment-options.html",
    component: _59bf2442,
    name: "payment-options___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewprivacy-policy-cookie-restriction-mode.html",
    component: _46c9eb80,
    name: "privacy-policy-cookie-restriction-mode___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewreset-password",
    component: _0fa47167,
    name: "customer-reset-password___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewreturns-refunds.html",
    component: _25d16cfe,
    name: "returns-refunds___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewshipping-delivery.html",
    component: _0cdef0cc,
    name: "shipping-delivery___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewterms-and-conditions",
    component: _b33c219c,
    name: "terms-and-conditions___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewtestimonials.html",
    component: _a9485e84,
    name: "testimonials___myjsusview___myjsauview"
  }, {
    path: "/myjsukview",
    component: _763b5a69,
    name: "home___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewabout-myjs-my-jewellery-story-our-story.html",
    component: _300f0c71,
    name: "about-us___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewcontact-us.html",
    component: _1c8662dd,
    name: "contact-us___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewerror.html",
    component: _2d995724,
    name: "error_find___myjsukview"
  }, {
    path: "/myjsukviewfaq.html",
    component: _03c11c08,
    name: "faq___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewpackaging-gift-wrapping.html",
    component: _50277415,
    name: "packaging-gift-wrapping___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewpayment-options.html",
    component: _59bf2442,
    name: "payment-options___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewprivacy-policy-cookie-restriction-mode.html",
    component: _46c9eb80,
    name: "privacy-policy-cookie-restriction-mode___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewreset-password",
    component: _0fa47167,
    name: "customer-reset-password___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewreturns-refunds.html",
    component: _25d16cfe,
    name: "returns-refunds___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewshipping-delivery.html",
    component: _0cdef0cc,
    name: "shipping-delivery___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewterms-and-conditions",
    component: _b33c219c,
    name: "terms-and-conditions___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewtestimonials.html",
    component: _a9485e84,
    name: "testimonials___myjsusview___myjsukview"
  }, {
    path: "/myjsusview",
    component: _763b5a69,
    name: "home___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewabout-myjs-my-jewellery-story-our-story.html",
    component: _300f0c71,
    name: "about-us___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewcontact-us.html",
    component: _1c8662dd,
    name: "contact-us___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewerror.html",
    component: _2d995724,
    name: "error_find___myjsusview"
  }, {
    path: "/myjsusviewfaq.html",
    component: _03c11c08,
    name: "faq___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewpackaging-gift-wrapping.html",
    component: _50277415,
    name: "packaging-gift-wrapping___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewpayment-options.html",
    component: _59bf2442,
    name: "payment-options___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewprivacy-policy-cookie-restriction-mode.html",
    component: _46c9eb80,
    name: "privacy-policy-cookie-restriction-mode___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewreset-password",
    component: _0fa47167,
    name: "customer-reset-password___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewreturns-refunds.html",
    component: _25d16cfe,
    name: "returns-refunds___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewshipping-delivery.html",
    component: _0cdef0cc,
    name: "shipping-delivery___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewterms-and-conditions",
    component: _b33c219c,
    name: "terms-and-conditions___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewtestimonials.html",
    component: _a9485e84,
    name: "testimonials___myjsusview___myjsusview"
  }, {
    path: "/packaging-gift-wrapping.html",
    component: _50277415,
    name: "packaging-gift-wrapping___myjsusview___"
  }, {
    path: "/payment-options.html",
    component: _59bf2442,
    name: "payment-options___myjsusview___"
  }, {
    path: "/privacy-policy-cookie-restriction-mode.html",
    component: _46c9eb80,
    name: "privacy-policy-cookie-restriction-mode___myjsusview___"
  }, {
    path: "/reset-password",
    component: _0fa47167,
    name: "customer-reset-password___myjsusview___"
  }, {
    path: "/returns-refunds.html",
    component: _25d16cfe,
    name: "returns-refunds___myjsusview___"
  }, {
    path: "/shipping-delivery.html",
    component: _0cdef0cc,
    name: "shipping-delivery___myjsusview___"
  }, {
    path: "/terms-and-conditions",
    component: _b33c219c,
    name: "terms-and-conditions___myjsusview___"
  }, {
    path: "/testimonials.html",
    component: _a9485e84,
    name: "testimonials___myjsusview___"
  }, {
    path: "//AboutUs",
    component: _300f0c71,
    name: "AboutUs___"
  }, {
    path: "//Account",
    component: _2179b693,
    name: "Account___"
  }, {
    path: "//cart",
    component: _1d513f9d,
    name: "cart___"
  }, {
    path: "//checkout",
    component: _a301d77a,
    name: "checkout___",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___"
    }]
  }, {
    path: "//Cms",
    component: _740316af,
    name: "Cms___"
  }, {
    path: "//Contacts",
    component: _1c8662dd,
    name: "Contacts___"
  }, {
    path: "//customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___"
    }, {
      path: "my-credit",
      component: _41ec6cd8,
      meta: {"titleLabel":"My credit"},
      name: "customer-my-credit___"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___"
    }, {
      path: "redeem-credit",
      component: _32722494,
      meta: {"titleLabel":"redeem credit"},
      name: "customer-redeem-credit___"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___"
    }]
  }, {
    path: "//Error",
    component: _2d995724,
    name: "Error___"
  }, {
    path: "//Faq",
    component: _03c11c08,
    name: "Faq___"
  }, {
    path: "//Home",
    component: _763b5a69,
    name: "Home___"
  }, {
    path: "//PackagingGiftWrapping",
    component: _50277415,
    name: "PackagingGiftWrapping___"
  }, {
    path: "//Page",
    component: _731f1fce,
    name: "Page___"
  }, {
    path: "//PaymentOptions",
    component: _59bf2442,
    name: "PaymentOptions___"
  }, {
    path: "//PrivacyPolicy",
    component: _46c9eb80,
    name: "PrivacyPolicy___"
  }, {
    path: "//ReturnsRefund",
    component: _25d16cfe,
    name: "ReturnsRefund___"
  }, {
    path: "//ShippingDelivery",
    component: _0cdef0cc,
    name: "ShippingDelivery___"
  }, {
    path: "//TermsAndConditions",
    component: _b33c219c,
    name: "TermsAndConditions___"
  }, {
    path: "//Testimonials",
    component: _a9485e84,
    name: "Testimonials___"
  }, {
    path: "/checkout/thankyou",
    component: _c5f8b548,
    name: "paypal_success___myjsusview___"
  }, {
    path: "/customer/account",
    component: _2179b693,
    name: "customer/account___myjsusview___"
  }, {
    path: "/myjsauview/AboutUs",
    component: _300f0c71,
    name: "AboutUs___myjsauview"
  }, {
    path: "/myjsauview/Account",
    component: _2179b693,
    name: "Account___myjsauview"
  }, {
    path: "/myjsauview/cart",
    component: _1d513f9d,
    name: "cart___myjsauview"
  }, {
    path: "/myjsauview/checkout",
    component: _a301d77a,
    name: "checkout___myjsauview",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___myjsauview"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___myjsauview"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___myjsauview"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___myjsauview"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___myjsauview"
    }]
  }, {
    path: "/myjsauview/Cms",
    component: _740316af,
    name: "Cms___myjsauview"
  }, {
    path: "/myjsauview/Contacts",
    component: _1c8662dd,
    name: "Contacts___myjsauview"
  }, {
    path: "/myjsauview/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___myjsauview",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___myjsauview"
    }, {
      path: "my-credit",
      component: _41ec6cd8,
      meta: {"titleLabel":"My credit"},
      name: "customer-my-credit___myjsauview"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___myjsauview"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___myjsauview"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___myjsauview"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___myjsauview"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___myjsauview"
    }, {
      path: "redeem-credit",
      component: _32722494,
      meta: {"titleLabel":"redeem credit"},
      name: "customer-redeem-credit___myjsauview"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___myjsauview"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___myjsauview"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___myjsauview"
    }]
  }, {
    path: "/myjsauview/Error",
    component: _2d995724,
    name: "Error___myjsauview"
  }, {
    path: "/myjsauview/Faq",
    component: _03c11c08,
    name: "Faq___myjsauview"
  }, {
    path: "/myjsauview/Home",
    component: _763b5a69,
    name: "Home___myjsauview"
  }, {
    path: "/myjsauview/PackagingGiftWrapping",
    component: _50277415,
    name: "PackagingGiftWrapping___myjsauview"
  }, {
    path: "/myjsauview/Page",
    component: _731f1fce,
    name: "Page___myjsauview"
  }, {
    path: "/myjsauview/PaymentOptions",
    component: _59bf2442,
    name: "PaymentOptions___myjsauview"
  }, {
    path: "/myjsauview/PrivacyPolicy",
    component: _46c9eb80,
    name: "PrivacyPolicy___myjsauview"
  }, {
    path: "/myjsauview/ReturnsRefund",
    component: _25d16cfe,
    name: "ReturnsRefund___myjsauview"
  }, {
    path: "/myjsauview/ShippingDelivery",
    component: _0cdef0cc,
    name: "ShippingDelivery___myjsauview"
  }, {
    path: "/myjsauview/TermsAndConditions",
    component: _b33c219c,
    name: "TermsAndConditions___myjsauview"
  }, {
    path: "/myjsauview/Testimonials",
    component: _a9485e84,
    name: "Testimonials___myjsauview"
  }, {
    path: "/myjsauviewcheckout/thankyou",
    component: _c5f8b548,
    name: "paypal_success___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewcustomer/account",
    component: _2179b693,
    name: "customer/account___myjsusview___myjsauview"
  }, {
    path: "/myjsukview/AboutUs",
    component: _300f0c71,
    name: "AboutUs___myjsukview"
  }, {
    path: "/myjsukview/Account",
    component: _2179b693,
    name: "Account___myjsukview"
  }, {
    path: "/myjsukview/cart",
    component: _1d513f9d,
    name: "cart___myjsukview"
  }, {
    path: "/myjsukview/checkout",
    component: _a301d77a,
    name: "checkout___myjsukview",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___myjsukview"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___myjsukview"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___myjsukview"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___myjsukview"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___myjsukview"
    }]
  }, {
    path: "/myjsukview/Cms",
    component: _740316af,
    name: "Cms___myjsukview"
  }, {
    path: "/myjsukview/Contacts",
    component: _1c8662dd,
    name: "Contacts___myjsukview"
  }, {
    path: "/myjsukview/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___myjsukview",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___myjsukview"
    }, {
      path: "my-credit",
      component: _41ec6cd8,
      meta: {"titleLabel":"My credit"},
      name: "customer-my-credit___myjsukview"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___myjsukview"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___myjsukview"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___myjsukview"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___myjsukview"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___myjsukview"
    }, {
      path: "redeem-credit",
      component: _32722494,
      meta: {"titleLabel":"redeem credit"},
      name: "customer-redeem-credit___myjsukview"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___myjsukview"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___myjsukview"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___myjsukview"
    }]
  }, {
    path: "/myjsukview/Error",
    component: _2d995724,
    name: "Error___myjsukview"
  }, {
    path: "/myjsukview/Faq",
    component: _03c11c08,
    name: "Faq___myjsukview"
  }, {
    path: "/myjsukview/Home",
    component: _763b5a69,
    name: "Home___myjsukview"
  }, {
    path: "/myjsukview/PackagingGiftWrapping",
    component: _50277415,
    name: "PackagingGiftWrapping___myjsukview"
  }, {
    path: "/myjsukview/Page",
    component: _731f1fce,
    name: "Page___myjsukview"
  }, {
    path: "/myjsukview/PaymentOptions",
    component: _59bf2442,
    name: "PaymentOptions___myjsukview"
  }, {
    path: "/myjsukview/PrivacyPolicy",
    component: _46c9eb80,
    name: "PrivacyPolicy___myjsukview"
  }, {
    path: "/myjsukview/ReturnsRefund",
    component: _25d16cfe,
    name: "ReturnsRefund___myjsukview"
  }, {
    path: "/myjsukview/ShippingDelivery",
    component: _0cdef0cc,
    name: "ShippingDelivery___myjsukview"
  }, {
    path: "/myjsukview/TermsAndConditions",
    component: _b33c219c,
    name: "TermsAndConditions___myjsukview"
  }, {
    path: "/myjsukview/Testimonials",
    component: _a9485e84,
    name: "Testimonials___myjsukview"
  }, {
    path: "/myjsukviewcheckout/thankyou",
    component: _c5f8b548,
    name: "paypal_success___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewcustomer/account",
    component: _2179b693,
    name: "customer/account___myjsusview___myjsukview"
  }, {
    path: "/myjsusview/AboutUs",
    component: _300f0c71,
    name: "AboutUs___myjsusview"
  }, {
    path: "/myjsusview/Account",
    component: _2179b693,
    name: "Account___myjsusview"
  }, {
    path: "/myjsusview/cart",
    component: _1d513f9d,
    name: "cart___myjsusview"
  }, {
    path: "/myjsusview/checkout",
    component: _a301d77a,
    name: "checkout___myjsusview",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___myjsusview"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___myjsusview"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___myjsusview"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___myjsusview"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___myjsusview"
    }]
  }, {
    path: "/myjsusview/Cms",
    component: _740316af,
    name: "Cms___myjsusview"
  }, {
    path: "/myjsusview/Contacts",
    component: _1c8662dd,
    name: "Contacts___myjsusview"
  }, {
    path: "/myjsusview/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___myjsusview",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___myjsusview"
    }, {
      path: "my-credit",
      component: _41ec6cd8,
      meta: {"titleLabel":"My credit"},
      name: "customer-my-credit___myjsusview"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___myjsusview"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___myjsusview"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___myjsusview"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___myjsusview"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___myjsusview"
    }, {
      path: "redeem-credit",
      component: _32722494,
      meta: {"titleLabel":"redeem credit"},
      name: "customer-redeem-credit___myjsusview"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___myjsusview"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___myjsusview"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___myjsusview"
    }]
  }, {
    path: "/myjsusview/Error",
    component: _2d995724,
    name: "Error___myjsusview"
  }, {
    path: "/myjsusview/Faq",
    component: _03c11c08,
    name: "Faq___myjsusview"
  }, {
    path: "/myjsusview/Home",
    component: _763b5a69,
    name: "Home___myjsusview"
  }, {
    path: "/myjsusview/PackagingGiftWrapping",
    component: _50277415,
    name: "PackagingGiftWrapping___myjsusview"
  }, {
    path: "/myjsusview/Page",
    component: _731f1fce,
    name: "Page___myjsusview"
  }, {
    path: "/myjsusview/PaymentOptions",
    component: _59bf2442,
    name: "PaymentOptions___myjsusview"
  }, {
    path: "/myjsusview/PrivacyPolicy",
    component: _46c9eb80,
    name: "PrivacyPolicy___myjsusview"
  }, {
    path: "/myjsusview/ReturnsRefund",
    component: _25d16cfe,
    name: "ReturnsRefund___myjsusview"
  }, {
    path: "/myjsusview/ShippingDelivery",
    component: _0cdef0cc,
    name: "ShippingDelivery___myjsusview"
  }, {
    path: "/myjsusview/TermsAndConditions",
    component: _b33c219c,
    name: "TermsAndConditions___myjsusview"
  }, {
    path: "/myjsusview/Testimonials",
    component: _a9485e84,
    name: "Testimonials___myjsusview"
  }, {
    path: "/myjsusviewcheckout/thankyou",
    component: _c5f8b548,
    name: "paypal_success___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewcustomer/account",
    component: _2179b693,
    name: "customer/account___myjsusview___myjsusview"
  }, {
    path: "//checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_cancel___"
  }, {
    path: "//checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_error___myjsusview___"
  }, {
    path: "//customer/my-profile",
    component: _6e20a5b0,
    name: "customer-my-profile___myjsusview___"
  }, {
    path: "//paypal/Cancel",
    component: _d3367a88,
    name: "paypal-Cancel___"
  }, {
    path: "//paypal/Return",
    component: _75868672,
    name: "paypal-Return___"
  }, {
    path: "/checkout/adyen/error.html",
    component: _7cfe6dbe,
    name: "adyen_error___myjsusview___"
  }, {
    path: "/checkout/afterpay/success",
    component: _24ce9645,
    name: "afterpay___myjsusview___"
  }, {
    path: "/customer/account/create",
    component: _ae68fb22,
    name: "customer/account/create___myjsusview___"
  }, {
    path: "/customer/account/createPassword",
    component: _0fa47167,
    name: "customer-reset-create-password___myjsusview___"
  }, {
    path: "/customer/account/login",
    component: _2179b693,
    name: "customer/account/login___myjsusview___"
  }, {
    path: "/myjsauview/checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_cancel___myjsauview"
  }, {
    path: "/myjsauview/checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_error___myjsusview___myjsauview"
  }, {
    path: "/myjsauview/customer/my-profile",
    component: _6e20a5b0,
    name: "customer-my-profile___myjsusview___myjsauview"
  }, {
    path: "/myjsauview/paypal/Cancel",
    component: _d3367a88,
    name: "paypal-Cancel___myjsauview"
  }, {
    path: "/myjsauview/paypal/Return",
    component: _75868672,
    name: "paypal-Return___myjsauview"
  }, {
    path: "/myjsauviewcheckout/adyen/error.html",
    component: _7cfe6dbe,
    name: "adyen_error___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewcheckout/afterpay/success",
    component: _24ce9645,
    name: "afterpay___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewcustomer/account/create",
    component: _ae68fb22,
    name: "customer/account/create___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewcustomer/account/createPassword",
    component: _0fa47167,
    name: "customer-reset-create-password___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewcustomer/account/login",
    component: _2179b693,
    name: "customer/account/login___myjsusview___myjsauview"
  }, {
    path: "/myjsauviewpaypal/action/return.html",
    component: _24ce9645,
    name: "paypal___myjsusview___myjsauview"
  }, {
    path: "/myjsukview/checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_cancel___myjsukview"
  }, {
    path: "/myjsukview/checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_error___myjsusview___myjsukview"
  }, {
    path: "/myjsukview/customer/my-profile",
    component: _6e20a5b0,
    name: "customer-my-profile___myjsusview___myjsukview"
  }, {
    path: "/myjsukview/paypal/Cancel",
    component: _d3367a88,
    name: "paypal-Cancel___myjsukview"
  }, {
    path: "/myjsukview/paypal/Return",
    component: _75868672,
    name: "paypal-Return___myjsukview"
  }, {
    path: "/myjsukviewcheckout/adyen/error.html",
    component: _7cfe6dbe,
    name: "adyen_error___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewcheckout/afterpay/success",
    component: _24ce9645,
    name: "afterpay___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewcustomer/account/create",
    component: _ae68fb22,
    name: "customer/account/create___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewcustomer/account/createPassword",
    component: _0fa47167,
    name: "customer-reset-create-password___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewcustomer/account/login",
    component: _2179b693,
    name: "customer/account/login___myjsusview___myjsukview"
  }, {
    path: "/myjsukviewpaypal/action/return.html",
    component: _24ce9645,
    name: "paypal___myjsusview___myjsukview"
  }, {
    path: "/myjsusview/checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_cancel___myjsusview"
  }, {
    path: "/myjsusview/checkout/cancel.html",
    component: _7cfe6dbe,
    name: "paypal_error___myjsusview___myjsusview"
  }, {
    path: "/myjsusview/customer/my-profile",
    component: _6e20a5b0,
    name: "customer-my-profile___myjsusview___myjsusview"
  }, {
    path: "/myjsusview/paypal/Cancel",
    component: _d3367a88,
    name: "paypal-Cancel___myjsusview"
  }, {
    path: "/myjsusview/paypal/Return",
    component: _75868672,
    name: "paypal-Return___myjsusview"
  }, {
    path: "/myjsusviewcheckout/adyen/error.html",
    component: _7cfe6dbe,
    name: "adyen_error___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewcheckout/afterpay/success",
    component: _24ce9645,
    name: "afterpay___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewcustomer/account/create",
    component: _ae68fb22,
    name: "customer/account/create___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewcustomer/account/createPassword",
    component: _0fa47167,
    name: "customer-reset-create-password___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewcustomer/account/login",
    component: _2179b693,
    name: "customer/account/login___myjsusview___myjsusview"
  }, {
    path: "/myjsusviewpaypal/action/return.html",
    component: _24ce9645,
    name: "paypal___myjsusview___myjsusview"
  }, {
    path: "/paypal/action/return.html",
    component: _24ce9645,
    name: "paypal___myjsusview___"
  }, {
    path: "/",
    component: _763b5a69,
    name: "home___myjsusview___"
  }, {
    path: "/myjsauviewsales/order/print/:orderId",
    component: _61ef6e2e,
    props: true,
    name: "print_payment___myjsusview___myjsauview"
  }, {
    path: "/myjsukviewsales/order/print/:orderId",
    component: _61ef6e2e,
    props: true,
    name: "print_payment___myjsusview___myjsukview"
  }, {
    path: "/myjsusviewsales/order/print/:orderId",
    component: _61ef6e2e,
    props: true,
    name: "print_payment___myjsusview___myjsusview"
  }, {
    path: "/sales/order/print/:orderId",
    component: _61ef6e2e,
    props: true,
    name: "print_payment___myjsusview___"
  }, {
    path: "/:slug+",
    component: _731f1fce,
    name: "page___myjsauview___"
  }, {
    path: "/myjsauview:slug+",
    component: _731f1fce,
    name: "page___myjsauview___myjsauview"
  }, {
    path: "/myjsukview:slug+",
    component: _731f1fce,
    name: "page___myjsauview___myjsukview"
  }, {
    path: "/myjsusview:slug+",
    component: _731f1fce,
    name: "page___myjsauview___myjsusview"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
