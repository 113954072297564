











import { defineComponent, } from "@nuxtjs/composition-api";
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'HeaderNavigationBlocks',
  components: { HTMLContent },
  props: {
    content: {
      type: String,
      required: false,
      default: '',
    },
  },
  
});
