























































































































// const identifiersMenuBlocks = ["menu_best", "personalised_menu", "menu_labdiamond", "menu_mix", "menu_bridal", "Collections_Menu", "earrings_menu", "rings_menu", "necklaces_menu", "bracelets_menu"];
import { SfOverlay, SfHeader, SfButton, SfBadge } from "@storefront-ui/vue";

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import { useUiHelpers, useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import SvgImage from "~/components/General/SvgImage.vue";
import HTMLContent from "~/components/HTMLContent.vue";
// import menuNotSale from "~/assets/menuNotSale.json";
// import menuSale from "~/assets/menuSale.json";
// import menu from "~/assets/menu.json";
import useCategory, {createCustomMenu} from "~/modules/catalog/category/composables/useCategory/index"
import HeaderNavigationBlocks from "./Header/Navigation/HeaderNavigationBlocks.vue";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    HTMLContent,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
    HeaderNavigationBlocks,
  },
  setup() {
    const categoryStore = useCategoryStore()
    const router = useRouter();
    const { app } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
    } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const {
      categories: categoryList,
      load: categoriesListLoad,
    } = useCategory();
    // const { hasCurrencyToSelect, hasStoresToSelect } =  useTopBar();
    // console.log("hasCurrencyToSelectH",hasCurrencyToSelect.value)
    // console.log("hasStoresToSelectH",hasStoresToSelect.value)
    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    // const categoryTree = ref<CategoryTree[]>([]);
    // let categoryTreeMenuNotSale = ref<CategoryTree[]>([]);
    // let categoryTreeMenuSale = ref<CategoryTree[]>([]);
    // let categoryTreeMenu = ref<CategoryTree[]>([]);
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push("/customer/my-profile");
      } else {
        toggleLoginModal();
      }
    };
    // categoryTreeMenuNotSale = createCustomMenu()(menuNotSale);
    // categoryTreeMenuSale = createCustomMenu()(menuSale);
    // categoryTreeMenu = createCustomMenu()(menu);
    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });
      categoryStore.$patch((state) => {
        state.rawCategoryList = categoryList.value
      })
      // categoryTree.value = categoryList.value?.[0]?.children.filter(
      //   (category) => category.include_in_menu
      // );
    });
    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      // categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      // hasCurrencyToSelect,
      // hasStoresToSelect,
      // categoryTreeMenuSale,
      // categoryTreeMenuNotSale,
      // categoryTreeMenu
    };
  },
});
