var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SfHeader", {
        staticClass: "sf-header--has-mobile-search",
        class: { "header-on-top": _vm.isSearchOpen },
        scopedSlots: _vm._u([
          {
            key: "logo",
            fn: function () {
              return [_c("HeaderLogo")]
            },
            proxy: true,
          },
          {
            key: "aside",
            fn: function () {
              return [_c("div", { staticClass: "sf-header__switchers" })]
            },
            proxy: true,
          },
          {
            key: "header-icons",
            fn: function (ref) {
              var activeIcon = ref.activeIcon
              return [
                _c(
                  "div",
                  { staticClass: "sf-header__icons" },
                  [
                    _c(
                      "SfButton",
                      {
                        directives: [
                          {
                            name: "e2e",
                            rawName: "v-e2e",
                            value: "app-header-account",
                            expression: "'app-header-account'",
                          },
                        ],
                        staticClass: "sf-button--pure sf-header__action",
                        attrs: {
                          "data-testid": "accountIcon",
                          "aria-label": "Account",
                        },
                        on: { click: _vm.handleAccountClick },
                      },
                      [
                        _c("SvgImage", {
                          class: {
                            "sf-header__icon is-active":
                              activeIcon === "account",
                          },
                          attrs: {
                            icon: _vm.accountIcon,
                            label: _vm.$t("Account"),
                            width: "1.25rem",
                            height: "1.25rem",
                          },
                        }),
                        _vm._v(" "),
                        _vm.isAuthenticated
                          ? _c(
                              "span",
                              { staticClass: "desktop-only sub-title" },
                              [_vm._v("My Account")]
                            )
                          : _c(
                              "span",
                              { staticClass: "desktop-only sub-title" },
                              [_vm._v("LogIn/Register")]
                            ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isAuthenticated
                      ? _c(
                          "SfButton",
                          {
                            staticClass: "sf-button--pure sf-header__action",
                            attrs: {
                              "data-testid": "wishlistIcon",
                              "aria-label": "Wishlist",
                            },
                            on: { click: _vm.toggleWishlistSidebar },
                          },
                          [
                            _c("SvgImage", {
                              staticClass: "sf-header__icon",
                              class: {
                                "sf-header__icon is-active":
                                  activeIcon === "wishlist",
                              },
                              attrs: {
                                icon: _vm.wishlistHasProducts
                                  ? "heart_fill"
                                  : "heart",
                                label: _vm.$t("Wishlist"),
                                width: "1.25rem",
                                height: "1.25rem",
                              },
                            }),
                            _vm._v(" "),
                            _vm.wishlistHasProducts
                              ? _c(
                                  "SfBadge",
                                  {
                                    staticClass: "sf-badge--number cart-badge",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.wishlistItemsQty) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "desktop-only sub-title" },
                              [_vm._v("Wishlist")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "SfButton",
                      {
                        directives: [
                          {
                            name: "e2e",
                            rawName: "v-e2e",
                            value: "app-header-cart",
                            expression: "'app-header-cart'",
                          },
                        ],
                        staticClass: "sf-button--pure sf-header__action",
                        attrs: { "aria-label": "Toggle cart sidebar" },
                        on: { click: _vm.toggleCartSidebar },
                      },
                      [
                        _c("SvgImage", {
                          staticClass: "sf-header__icon",
                          class: {
                            "sf-header__icon is-active": activeIcon === "cart",
                          },
                          attrs: {
                            icon: "empty_cart",
                            label: _vm.$t("Cart"),
                            width: "20",
                            height: "20",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "desktop-only sub-title" }, [
                          _vm._v("Cart"),
                        ]),
                        _vm._v(" "),
                        _vm.cartTotalItems
                          ? _c(
                              "SfBadge",
                              { staticClass: "sf-badge--number cart-badge" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.cartTotalItems) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "search",
            fn: function () {
              return [
                _c("SearchBar", {
                  attrs: { "is-search-open": _vm.isSearchOpen },
                  on: {
                    "set-is-open": function ($event) {
                      _vm.isSearchOpen = $event
                    },
                    "set-search-results": function ($event) {
                      _vm.productSearchResults = $event
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.isSearchOpen
        ? _c("SearchResults", {
            attrs: {
              visible: _vm.isSearchOpen,
              "search-results": _vm.productSearchResults,
            },
            on: {
              close: function ($event) {
                _vm.isSearchOpen = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("SfOverlay", { attrs: { visible: _vm.isSearchOpen } }),
      _vm._v(" "),
      _c("nav", { staticClass: "navbar" }, [_c("header-navigation-blocks")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }