var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label === "SALE"
    ? _c(
        "SfLink",
        {
          staticClass: "sf-header-navigation-item__link_sale",
          attrs: { link: _vm.link },
        },
        [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
      )
    : _c(
        "SfLink",
        {
          staticClass: "sf-header-navigation-item__link",
          attrs: { link: _vm.link },
        },
        [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }