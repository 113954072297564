var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.tag, {
    tag: "component",
    domProps: { innerHTML: _vm._s(_vm.content) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }