var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("IconSprite"),
      _vm._v(" "),
      _vm.isCartSidebarOpen ? _c("CartSidebar") : _vm._e(),
      _vm._v(" "),
      _vm.isWishlistSidebarOpen ? _c("WishlistSidebar") : _vm._e(),
      _vm._v(" "),
      _vm.isLoginModalOpen && !_vm.isForgotModalOpen
        ? _c("LoginModal", { on: { close: _vm.toggleLoginModal } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoginModalOpen && _vm.isForgotModalOpen
        ? _c("LoginModal", {
            attrs: { currentlyDisplayed: "forgotPassword" },
            on: { close: _vm.toggleForgotModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "LazyHydrate",
        { attrs: { "when-visible": "" } },
        [_c("Notification")],
        1
      ),
      _vm._v(" "),
      _c("AppHeader"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "layout" } },
        [_c("nuxt", { key: _vm.route.fullPath })],
        1
      ),
      _vm._v(" "),
      _c("BottomNavigation"),
      _vm._v(" "),
      _c("LoadWhenVisible", [_c("AppFooter")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }