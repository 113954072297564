var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "smartphone-only" },
    [
      _c(
        "SfBottomNavigation",
        { staticClass: "navigation-bottom" },
        [
          _c("SfBottomNavigationItem", {
            class: {
              "sf-bottom-navigation__item--active":
                _vm.$route.name && _vm.$route.name.startsWith("home"),
            },
            attrs: {
              label: _vm.$t("Home"),
              "data-testid": "bottom-navigation-home",
            },
            on: { click: _vm.handleHomeClick },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("SvgImage", {
                      attrs: {
                        icon: "home",
                        label: _vm.$t("Home"),
                        width: "20",
                        height: "20",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("SfBottomNavigationItem", {
            attrs: {
              label: _vm.$t("Menu"),
              "data-testid": "bottom-navigation-menu",
            },
            on: { click: _vm.loadCategoryMenu },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("SvgImage", {
                      attrs: {
                        icon: "menu",
                        label: _vm.$t("Menu"),
                        width: "20",
                        height: "20",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c("SfBottomNavigationItem", {
                attrs: {
                  label: _vm.$t("Wishlist"),
                  "data-testid": "bottom-navigation-wishlist",
                },
                on: { click: _vm.toggleWishlistSidebar },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("SvgImage", {
                            attrs: {
                              icon: "heart",
                              label: _vm.$t("Wishlist"),
                              width: "20",
                              height: "20",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2208007988
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c("SfBottomNavigationItem", {
            attrs: {
              label: _vm.$t("Account"),
              "data-testid": "bottom-navigation-account",
            },
            on: { click: _vm.handleAccountClick },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("SvgImage", {
                      attrs: {
                        icon: "profile",
                        label: _vm.$t("Account"),
                        width: "20",
                        height: "20",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("SfBottomNavigationItem", {
            attrs: {
              label: _vm.$t("Cart"),
              "data-testid": "bottom-navigation-cart",
            },
            on: { click: _vm.toggleCartSidebar },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [
                    _c(
                      "SfCircleIcon",
                      { attrs: { "aria-label": "Go to cart" } },
                      [
                        _c("SvgImage", {
                          staticClass: "navigation-bottom__add-to-cart",
                          attrs: {
                            icon: "add_to_cart",
                            width: "25",
                            height: "25",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMobileMenuOpen ? _c("MobileCategorySidebar") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }