var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "svg-image",
      attrs: {
        "aria-label": _vm.label,
        role: _vm.role || (_vm.label ? "img" : null),
      },
    },
    [
      _c("svg", { attrs: { width: _vm.width, height: _vm.height } }, [
        _c("use", { attrs: { "xlink:href": "#" + _vm.icon } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }